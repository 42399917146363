import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import { Input } from "semantic-ui-react";

import { useHttp } from "../../hooks/http.hook";

import "./Header.scss";

import {
  logoName,
  logoNameConcise,
  mainPage,
  mainPageRu,
  genres,
  genresRu,
  filter,
  filterRu,
  search,
} from "../../constants";
import {useDebounce} from "react-use";

const timer = 5000;

const Header = () => {
  const { request } = useHttp();
  const navigate = useNavigate();

  const [isMove, setIsMove] = useState(false);
  const [init, setInit] = useState(true);
  const [closed, setClosed] = useState(true);
  const [searching, setSearching] = useState(false);
  const [randomId, setRandomId] = useState("");
  const [searchText, setSearchText] = useState("");

  const getRandomId = async () => {
    try {
      const data = await request("/hentay/random", "GET");
      if (data) setRandomId(data);
      if (!closed) setClosed(!closed);
    } catch (ex) {}
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchText(val)
  };

  useDebounce(() => navigateSearch(searchText), 300, [searchText])

  const navigateSearch = (val) => {
      let location = search + "/";
      if (val) location += val;
      else location += "_";
      navigate(location);
  };

  const noop = () => {};

  const reverseClose = () => setClosed(!closed);

  useEffect(() => {
    setTimeout(() => setInit(false), timer);
  }, [init]);

  useEffect(() => {
      randomId && navigate(`/id/${randomId}`)
  }, [randomId])

  return (
    <header id="header">
      <div className="header">
        <Link to="/">
          <div
            onMouseLeave={() => setIsMove(false)}
            onMouseEnter={() => setIsMove(true)}
            className="header__logo"
          >
            <h2
              className={`header__logo--name ${
                isMove || init ? "transfuse" : ""
              } ${searching ? "hide" : ""}`}
            >
              {logoName}
            </h2>
            <h2
              className={`header__logo--name--concise ${
                searching ? "show" : ""
              }`}
            >
              {logoNameConcise}
            </h2>
          </div>
        </Link>
        <div className={`header__links ${closed ? "closed" : ""}`}>
          <NavLink
            to={mainPage}
            className={({ isActive }) =>
                isActive ? "active" : ""
            }
            title={mainPageRu}
            onClick={closed ? noop : reverseClose}
          >
            {mainPageRu}
          </NavLink>
          <NavLink
            end
            to={genres}
            className={({ isActive }) =>
                isActive ? "active" : ""
            }
            title={genresRu}
            onClick={closed ? noop : reverseClose}
          >
            {genresRu}
          </NavLink>
          <NavLink
            end
            to={filter}
            className={({ isActive }) =>
                isActive ? "active" : ""
            }
            title={filterRu}
            onClick={closed ? noop : reverseClose}
          >
            {filterRu}
          </NavLink>
          <div className="header__random--mobile">
            <h2 onClick={getRandomId}>Случайный хентай</h2>
          </div>
        </div>
        <div className="header__search">
          <div className={`header__search--input ${searching ? "active" : ""}`}>
            <Input
              size="mini"
              loading={false}
              error={true}
              onChange={handleSearch}
              placeholder="Поиск . . ."
            />
          </div>
          <div
            className="header__search--button"
            onClick={() => setSearching(!searching)}
          >
            {searching ? (
              <Close className="close" />
            ) : (
              <Search className="search" />
            )}
          </div>
        </div>
        <div className="header__random">
          <h2 onClick={getRandomId}>Случайный хентай</h2>
        </div>
        <div
          onClick={() => setClosed(!closed)}
          className={`btnHL ${closed ? "" : "active"}`}
        >
          <div className="containerHL">
            <div className="log"></div>
            <div className="log"></div>
            <div className="log"></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
