import React, {lazy, Suspense, useRef} from "react";
import {
    BrowserRouter,
    Routes,
    Route, Navigate
} from "react-router-dom";

import { Header, Footer } from "./components";

import "./App.scss";

import scrollerSvg from "./assets/img/heart.svg";
import iosPictureExtra from "./assets/img/hentay_28.jpg";
import iosPictureMedium from "./assets/img/hentay_28_middle.jpg";
import iosPictureSmall from "./assets/img/hentay_28_mini.jpg";
import pictureExtra from "./assets/img/hentay_28.webp";
import pictureMedium from "./assets/img/hentay_28_middle.webp";
import pictureSmall from "./assets/img/hentay_28_mini.webp";
import {filter, genres, mainPage, search} from "./constants";
import {Spin} from "antd";

const Picture = () => {
  let width = window.innerWidth;
  if (/iPhone|iPad|iPod/i.test(navigator.platform)) {
    if (width > 1199) return <img src={iosPictureExtra} alt="pica" />;
    else if (width > 799) return <img src={iosPictureMedium} alt="pica" />;
    else return <img src={iosPictureSmall} alt="pica" />;
  } else {
    if (width > 1199) return <img src={pictureExtra} alt="pica" />;
    else if (width > 799) return <img src={pictureMedium} alt="pica" />;
    else return <img src={pictureSmall} alt="pica" />;
  }
};

const Main = lazy(() => import("./pages/Main"));
const VideoPage = lazy(() => import("./pages/VideoPage"));
const Genres = lazy(() => import("./pages/Genres"));
const Filter = lazy(() => import("./pages/Filter"));
const Search = lazy(() => import("./pages/Search"));

export default () => {
  const scroller = useRef(null);

  window.addEventListener("scroll", () => {
    if (window.pageYOffset === 0) scroller.current.classList.add("fade");
    else scroller.current.classList.remove("fade");
  });

  const scrollHandler = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  return (
      <BrowserRouter>
        <div
          className="app"
        >
          <div className="picture">
            <Picture />
          </div>
          <Header />
          <div className="container">
                    <Routes>
                        <Route index element={<Suspense fallback={<Spin size="large" />}><Main /></Suspense>} />
                        <Route path={mainPage} element={<Suspense fallback={<Spin size="large" />}><Main /></Suspense>} />
                        <Route path={mainPage + "/:genreId"} element={<Suspense fallback={<Spin size="large" />}><Main /></Suspense>} />
                        <Route path={search + "/:word"} element={<Suspense fallback={<Spin size="large" />}><Search /></Suspense>} />
                        <Route path={filter} element={<Suspense fallback={<Spin size="large" />}><Filter /></Suspense>} />
                        <Route path={genres} element={<Suspense fallback={<Spin size="large" />}><Genres /></Suspense>} />
                        <Route path='/id/:id' element={<Suspense fallback={<Spin size="large" />}><VideoPage /></Suspense>} />
                        <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                        />
                    </Routes>
          </div>
          <div
            ref={scroller}
            onClick={scrollHandler}
            className={`scroller fade`}
          >
            <img src={scrollerSvg} alt="scroller" />
          </div>
          <Footer />
        </div>
      </BrowserRouter>
  );
};
