import React from "react";

import "./Footer.scss";

import {logoName} from "../../constants";

const Footer = () => {
  return (
    <footer>
      <span className="one"><h2>{logoName}</h2> все права защищены 2023 - 2027</span>
      <span className="one">Широкий выбор хентая и жанров, с удобным фильтром</span>
      <span className="one">По поводу авторских права пишите админам, видео будет удалено!</span>
    </footer>
  );
};

export default Footer;
