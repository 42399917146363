import { header, mobHeader, maxSize } from "../constants";

let height = header;
const size = window.screen.availWidth;
if (size < maxSize) height = mobHeader;
const topPosition = -1 * height - 10;

export const scrollToElement = async (page) => {
  let el = document.querySelector(".page-" + page)

  let selectedPosY = topPosition;
  while (el != null) {
    selectedPosY += el.offsetTop;
    el = el.offsetParent;
  }
  window.scrollTo(0, selectedPosY);
};

export const scrollToObject = async (className) => {
  let el = await new Promise((res, _) =>
    res(document.getElementsByClassName(className)[0])
  );

  let selectedPosY = topPosition;
  while (el != null) {
    selectedPosY += el.offsetTop;
    el = el.offsetParent;
  }
  window.scrollTo(0, selectedPosY);
};

export const scrollToId = (id) => {
  let el = document.getElementById(id);

  let selectedPosY = topPosition;
  while (el != null) {
    selectedPosY += el.offsetTop;
    el = el.offsetParent;
  }
  window.scrollTo(0, selectedPosY);
};
