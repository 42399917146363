import React from "react";

import "./FilterHead.scss";

const FilterHead = props => {
  return <div className="filter__head">
    <span className="quantity">Количество видео по вашему запросу: {props.quantity}</span>
    {props.children}
    </div>;
};

export default FilterHead;
