import React from "react";

import "./PlayerPager.scss";

import {pageLimit} from "../../../constants";

const array = new Array(5);
for (let i = 0; i < pageLimit; i++) array[i] = 1;

const SmallPager = ({ updateParentState, pages }) => {
  const array = new Array(pages);
  for (let i = 0; i < pages; i++) array[i] = 1;
  return array.map((_, i) => (
    <div
      key={i}
      data-page={i}
      className={`video__pager--item ${i === 0 ? "active" : ""}`}
      onClick={e => {
        const val = e.target.dataset.page;
        updateParentState(val);
        const items = document.querySelectorAll(".video__pager--item");
        items.forEach(item => item.classList.remove("active"));
        e.target.classList.add("active");
      }}
    >
      {i + 1}
    </div>
  ));
};

const BigPager = ({ pages, updateParentState, currentState }) => {
  //const page = useContext(PageContext);

  const handlerPager = e => {
    const currPage = e.target.dataset.page;
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    e.target.classList.add("active");
  };

  const handlerPagerStart = e => {
    const currPage = Number(e.target.dataset.page);
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    if (currPage <= 5) e.target.classList.add("active");
    else {
      if (pages === 8) items[4].classList.add("active");
      else if (pages === 10) items[2].classList.add("active");
      else items[3].classList.add("active");
    }
  };

  const handlerPagerMid1 = e => {
    const currPage = Number(e.target.dataset.page);
    console.log("handlerPagerMid1", currPage);
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    items[3].classList.add("active");
  };

  const handlerPagerMid2 = e => {
    const currPage = Number(e.target.dataset.page);
    console.log("handlerPagerMid2", currPage);
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    if (currPage === 5) items[4].classList.add("active");
    else items[3].classList.add("active");
  };

  const noop = () => {};

  const handlerPagerMid4 = e => {
    console.log("handlerPagerMid4");
    const currPage = Number(e.target.dataset.page);
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    if (currPage === pages - 4) items[2].classList.add("active");
    else items[3].classList.add("active");
  };

  const handlerPagerMid5 = e => {
    console.log("handlerPagerMid5");
    const currPage = Number(e.target.dataset.page);
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    items[3].classList.add("active");
  };

  const handlerPagerEnd = e => {
    const currPage = Number(e.target.dataset.page);
    updateParentState(currPage);
    const items = document.querySelectorAll(".video__pager--item");
    items.forEach(item => item.classList.remove("active"));
    if (currPage === 5) items[4].classList.add("active");
    else if (currPage === 4) items[3].classList.add("active");
    else if (currPage === 3) items[2].classList.add("active");
    else e.target.classList.add("active");
  };

  const curr = currentState;
  const first = (
    <div
      className={`video__pager--item active`}
      data-page={1}
      onClick={handlerPager}
    >
      1
    </div>
  );
  const last = (
    <div
      className={`video__pager--item `}
      data-page={pages}
      onClick={handlerPager}
    >
      {pages}
    </div>
  );
  let middle = null;
  if (curr <= 5)
    middle = array.map((_, i) => (
      <div
        key={i}
        className={`video__pager--item `}
        data-page={2 + i}
        onClick={handlerPagerStart}
      >
        {i + 2 === 6 ? "..." : 2 + i}
      </div>
    ));
  else if (curr > pages - 5)
    middle = array.map((_, i) => (
      <div
        key={i}
        className={`video__pager--item `}
        data-page={pages - pageLimit + i}
        onClick={handlerPagerEnd}
      >
        {i + 2 === 2 ? "..." : pages - pageLimit + i}
      </div>
    ));
  else
    middle = array.map((_, i) => (
      <div
        key={i}
        className={`video__pager--item `}
        data-page={curr - (pageLimit - 1) / 2 + i}
        onClick={
          i === 0
            ? handlerPagerMid1
            : i === 1
            ? handlerPagerMid2
            : i === 2
            ? noop
            : i === 3
            ? handlerPagerMid4
            : handlerPagerMid5
        }
      >
        {i === 0 || i === 4 ? "..." : curr - (pageLimit - 1) / 2 + i}
      </div>
    ));
  return (
    <>
      {first}
      {middle}
      {last}
    </>
  );
};

export default ({ updateParentState, currentState, pages }) => {
  currentState = Number(currentState);
  pages = Number(pages);

  return (
    <>
      {pages !== 0 && pages !== 1 && (
        <div className="video__pager">
          {pages < pageLimit + 3 ? (
            <SmallPager
              updateParentState={updateParentState}
              pages={pages}
              currentState={currentState}
            />
          ) : (
            <BigPager
              updateParentState={updateParentState}
              pages={pages}
              currentState={currentState}
            />
          )}
        </div>
      )}
    </>
  );
};
