import React from "react";

import "./QueryMessage.scss";

const QueryMessage = ({ children }) => (
  <div className="catalog__query">
    <div className="catalog__query--message">{children}</div>
  </div>
);

export default QueryMessage;
