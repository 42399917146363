export const logoName = "HentayStorm";
export const logoNameConcise = "HS";

export const mainPage = "/catalog";
export const mainPageRu = "Каталог видео";
export const genres = "/genres";
export const genresRu = "Все жанры";
export const filter = "/filters";
export const filterRu = "Фильтры";
export const search = "/search";

export const header = 50;
export const mobHeader = 40;

export const defaultSeries = 1;
export const defaultDuration = 25;

export const release_date_from = 1990;
export const release_date_to = 2020;
export const series_number_from = 1;
export const series_number_to = 24;
export const duration_from = 15;
export const duration_to = 180;

export const pageLimit = 5;
export const cardsOnPage = 16;

export const primary = "#d13b40";
export const secondary = "orange";

export const maxSize = 650;
export const maxSizeVideo = 860;
export const videoPlayerMobSize = 600;

// export const serverUrl = "https://hentay-storm.api.nichrome.dev/";
export const serverUrl = process.env.REACT_APP_API_URL
