import React from "react";

import "./Pager.scss";

import {pageLimit} from "../../../constants";

const SmallPager = ({ updateParentState, currentState, pages }) => {
  const handleClick = (e) => updateParentState(e.target.dataset.page);
  const array = new Array(pages);
  for (let i = 0; i < pages; i++) array[i] = 1;

  return array.map((_, i) => (
    <button
      key={i + 1}
      data-page={i + 1}
      className={`catalog__pager--item ${
        i + 1 === currentState ? "active" : ""
      }`}
      disabled={i + 1 === currentState}
      onClick={handleClick}
    >
      {i + 1}
    </button>
  ));
};

const BigPager = ({ updateParentState, currentState, pages }) => {
  const handleClick = (e) => updateParentState(e.target.dataset.page);
  const curr = currentState;

  const first = (
    <button
      className={`catalog__pager--item ${curr === 1 ? "active" : ""}`}
      data-page={1}
      disabled={curr === 1}
      onClick={handleClick}
    >
      1
    </button>
  );

  let middle = null;
  const array = new Array(5);
  for (let i = 0; i < 5; i++) array[i] = 1;

  if (curr <= 5)
    middle = array.map((_, i) => (
      <button
        key={i}
        className={`catalog__pager--item ${curr === 2 + i ? "active" : ""}`}
        data-page={2 + i}
        disabled={curr === 2 + i}
        onClick={handleClick}
      >
        {i + 2 === 6 ? "..." : 2 + i}
      </button>
    ));
  else if (curr > pages - 4)
    middle = array.map((_, i) => (
      <button
        key={i}
        className={`catalog__pager--item ${
          curr === pages - pageLimit + i + 1 ? "active" : ""
        }`}
        disabled={curr === pages - pageLimit + i + 1}
        data-page={pages - pageLimit + i + 1}
        onClick={handleClick}
      >
        {i + 2 === 2 ? "..." : pages - pageLimit + i + 1}
      </button>
    ));
  else
    middle = array.map((_, i) => (
      <button
        key={i}
        className={`catalog__pager--item ${
          curr === curr - (pageLimit - 1) / 2 + i ? "active" : ""
        }`}
        data-page={curr - (pageLimit - 1) / 2 + i}
        disabled={curr === curr - (pageLimit - 1) / 2 + i}
        onClick={handleClick}
      >
        {i === 0 || i === 4 ? "..." : curr - (pageLimit - 1) / 2 + i}
      </button>
    ));
  return (
    <>
      {first}
      {middle}
    </>
  );
};

export default ({ updateParentState, currentState, pages }) => {
  currentState = Number(currentState);
  pages = Number(pages);
  return (
    <>
      {pages !== 0 && pages !== 1 && (
        <div className="catalog__pager">
          {pages < pageLimit + 1 ? (
            <SmallPager
              updateParentState={updateParentState}
              pages={pages}
              currentState={currentState}
            />
          ) : (
            <BigPager
              updateParentState={updateParentState}
              pages={pages}
              currentState={currentState}
            />
          )}
        </div>
      )}
    </>
  );
};
