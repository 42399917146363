import React, {useEffect, useRef} from "react";

import { Card } from "../../layouts";

import "./MainCatalog.scss";

import {cardsOnPage} from "../../../constants";
import {Spin} from "antd";
import {useInView} from "react-intersection-observer";

const Catalog = ({ page, onEmit, array, isLoading }) => {
    const { ref, inView } = useInView({
        root: null,
        marginRoot: "0px",
        threshold: 0.1,
    })

    useEffect(() => {
        inView && !isLoading && (!array || !array.length) && onEmit(page)
    }, [inView, isLoading, array])

    const catalog = array.map((item, i) => {
        return <Card
            key={item.id}
            {...item}
            pageName={i % cardsOnPage === 0 ? `page-${i / cardsOnPage + 1}` : ""}
            lastPageName={(i + 1) % cardsOnPage === 0 ? `last-page-el-${(i + 1) / cardsOnPage}` : ""}
        />
    });
    return <div ref={ref} className={`main__catalog${isLoading ? ' loading' : ''}`}>{isLoading ? <Spin size="large" /> : catalog}</div>;
}

const MainCatalog = ({ array, onEmit }) => {

  const catalog = array.map((item, i) => {
      return <Catalog key={i} page={i} array={item.array} isLoading={item.isLoading} onEmit={onEmit} />
  });
  return <div className="main__catalog-wrapper">{catalog}</div>;
};

export default MainCatalog;
