import React from "react";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Link } from "react-router-dom";

import { NumberConvertor } from "../../../utils";

import "./Card.scss";

const defaultPicture = "/static/img/hentay_13.jpg";
const defaultPath = "/static/avatars/";

const Card = ({
  id,
  avatar,
  name,
  genre_ru,
  watched,
  likes,
  pageName,
  lastPageName,
}) => {
  const Watched = NumberConvertor(watched);
  const Likes = NumberConvertor(likes);
  let picture = defaultPicture;
  if (avatar) picture = avatar;
  return (
    <div className={`catalog__card ${pageName} ${lastPageName}`}>
      <Link to={`/id/${id}`} target="_blank">
        <div className="card__image">
          <img src={picture} alt={id} />
        </div>
        <div className="card__desc">
          <h1 className="card__desc--name">{name}</h1>
          <h2 className="card__desc--genres">
            {genre_ru.map((item) => item.replaceAll('-', ' ') + " ")}
          </h2>
        </div>
        <div className="card__desc__features">
          <div className="card__desc__features--eye">
            <RemoveRedEyeIcon />
            <span>{Watched}</span>
          </div>
          <div className="card__desc__features--heart">
            <FavoriteBorderIcon className="heart" />
            <span>{Likes}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
