import React from "react";

import "./MainHead.scss";

const defaultPath = "/static/img/";
const main = "hentay_30";
const genres = "hentay_23";
const search = "hentay_59";

const MainHead = ({ children, image }) => {
  let picture = defaultPath;
  if (image === "search") picture += search;
  else if (image === "genres") picture += genres;
  else picture += main;
  return (
    <div className="main__head">
      <div className="main__head--desc">{children}</div>
      <div className="main__head--image">
        <picture>
          <source type="image/webp" srcSet={`${picture}.webp`} />
          <source type="image/jpeg" srcSet={`${picture}.jpg`} />
          <img src={`${picture}.jpg`} alt={`pica`} />
        </picture>
      </div>
    </div>
  );
};

export default MainHead;
