import React, { Fragment, useState, useEffect } from "react";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { useHttp } from "../../../hooks/http.hook";
import { QueryMessage } from "../../layouts";
import { scrollToId } from "../../../utils/scrollTo";
import { getCommon, getGenres } from "../../../utils/getStrings";

import "./VideoHead.scss";

import playButton from "../../../assets/img/play_button.svg";
import similarButton from "../../../assets/img/similar_button.svg";
import descriptionButton from "../../../assets/img/description_button.svg";

// const defaultPath = "/static/avatars/";
const defaultPicture = "/static/img/hentay_13.jpg";

const moveToDescription = () => scrollToId("description");
const moveToPlayer = () => scrollToId("player");
const moveToSimilar = () => scrollToId("similar");

const VideoHead = ({
  _id,
  name,
  genre_ru,
  voice_studio,
  series_number,
  duration,
  studio,
  release_date,
  description,
  avatar,
  watched,
  likes,
}) => {
  const [currWatched, setCurrWatched] = useState(watched);
  const [currLikes, setCurrLikes] = useState(likes);
  const [likeClicked, setLikeClicked] = useState(false);
  const { request } = useHttp();

  useEffect(() => setCurrWatched(watched), [watched]);
  useEffect(() => setCurrLikes(likes), [likes]);

  const updateLike = async () => {
    try {
      if (!likeClicked) var inc = 1;
      else inc = -1;
      const tmp = currLikes + inc;
      setLikeClicked(!likeClicked);
      setCurrLikes(tmp);
      await request("../api/object/updateLike", "POST", { id: _id, inc: inc });
    } catch (ex) {}
  };

  let picture = defaultPicture;
  if (avatar) picture = avatar;
  return (
    <Fragment>
      <div className="video__head">
        <div className="video__head--image">
          <img src={picture} alt="pic1" />
        </div>
        <div className="video__head--desc">
          <div className="desc--top">
            <h1>
              <span className="highlight">Название: </span>
              {getCommon(name)}
            </h1>
            <h2 className="genres">
              <span className="highlight">Жанры: </span>
              {getGenres(genre_ru)}
            </h2>
            <h2>
              <span className="highlight">Студия озвучки: </span>
              {getCommon(voice_studio)}
            </h2>
            <h2>
              <span className="highlight">Количество серий: </span>
              {getCommon(series_number)}
            </h2>
            <h2>
              <span className="highlight">Продолжительность: </span>
              {getCommon(duration)}
            </h2>
            <h2>
              <span className="highlight">Студия: </span>
              {getCommon(studio)}
            </h2>
            <h2>
              <span className="highlight">Дата релиза: </span>
              {getCommon(release_date)}
            </h2>
          </div>
          <div className="desc--bottom">
            <div className="layouts">
              <RemoveRedEyeIcon />
              <div className="quantity">{currWatched}</div>
            </div>
            <div
              className={`layouts ${likeClicked ? "heart" : ""}`}
              onClick={updateLike}
            >
              <FavoriteBorderIcon />
              <div className="quantity">{currLikes}</div>
            </div>
            {description && (
              <div className="layouts" onClick={moveToDescription}>
                <span>Описание</span>
                <img src={descriptionButton} alt="but-3" />
              </div>
            )}
            <div className="layouts" onClick={moveToPlayer}>
              <span>Смотреть</span>
              <img src={playButton} alt="but-1" />
            </div>
            <div className="layouts" onClick={moveToSimilar}>
              <span>Похожие</span>
              <img src={similarButton} alt="but-2" />
            </div>
          </div>
        </div>
      </div>
      {description && (
        <Fragment>
          <div id="description">
            <QueryMessage>описание</QueryMessage>
          </div>
          <div className="video__head__bottom">
            <h2>{getCommon(description)}</h2>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default VideoHead;
