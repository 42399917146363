import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Player } from "video-react";

import { useHttp } from "../../../hooks/http.hook";
import {PlayerPager} from "../../layouts";

import "./VideoPlayer.scss";

import defaultPoster from "../../../assets/img/hentay_4.jpg";

import {maxSizeVideo} from "../../../constants";

// const defaultPath = "/static/videos/";
const defaultPath = "";

const VideoPlayer = ({ links }) => {
  const { request } = useHttp();
  const { id } = useParams();
  const [videos, setVideos] = useState(links);
  const [currentVideos, setCurrentVideos] = useState([]);
  let video = defaultPath + currentVideos;
  useEffect(() => {
    setVideos(links);
    setCurrentVideos(links ? links[0] : ["default_video.mp4"]);
  }, [links]);
  useEffect(() => {
    const fetchData = async id => {
      const send = {
        id: id
      };
      await request("../api/object/updateWatched", "POST", send);
    };
    fetchData(id);
  }, [id, request]);

  const handleSeries = e => {
    const fetchData = async id => {
      const send = {
        id: id
      };
      await request("../api/object/updateWatched", "POST", send);
    };
    const el = e.target;
    if (el.classList.contains("active")) return;
    fetchData(id);
    setCurrentVideos(el.dataset.series);
    const items = document.querySelectorAll(".video__series--item");
    items.forEach(item => item.classList.remove("active"));
    el.classList.add("active");
  };

  const handleSeriesMobile = i => {
    const fetchData = async id => {
      const send = {
        id: id
      };
      await request("../api/object/updateWatched", "POST", send);
    };
    fetchData(id);
    setCurrentVideos(videos[i]);
  };

  const series =
    videos &&
    videos[0].length &&
    videos.map((item, i) => {
      let active = "";
      if (i === 0) active = "active";
      return (
        <div
          key={i}
          data-series={item}
          onClick={handleSeries}
          className={`video__series--item ${active}`}
        >
          серия {i + 1}
        </div>
      );
    });

  let isMobile = false;
  if (window.innerWidth < maxSizeVideo) isMobile = true;

  let currentState =
  videos && videos[0].length && videos.indexOf(currentVideos);
  if (currentState < 0) currentState = 0;

  return (
    <div className="all_players" id="player">
      <div className="video__player">
        <Player playsInline poster={defaultPoster} src={video} />
      </div>
      {videos && videos.length && isMobile ? (
        <PlayerPager
          updateParentState={handleSeriesMobile}
          pages={videos && videos[0].length && videos.length}
          currentState={currentState}
        />
      ) : (
        <div className="video__series">{series}</div>
      )}
    </div>
  );
};

export default VideoPlayer;
