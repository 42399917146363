import React from "react";

import { GenreCard } from "../../layouts";

import "./GenresCatalog.scss";

const GenresCatalog = ({array}) => {
  const catalog = array.map((item, i) => <GenreCard key={i} {...item} />);
  return <div className="main__catalog genres__catalog">{catalog}</div>;
};

export default GenresCatalog;
