import React from "react";
import { Link } from "react-router-dom";

import "./GenreCard.scss";

import { mainPage } from "../../../constants";

const defaultPicture = "/static/img/hentay_13.jpg";
const defaultPath = "/static/genres/";

const GenreCard = ({ name, name_ru, avatar }) => {
  let picture = defaultPicture;
  if (avatar) picture = defaultPath + avatar;
  return (
    <div className="catalog__genre-card">
      <Link to={`${mainPage}/${name}`} target="_blank">
        <div className="genre-card__image">
          <picture>
            <source type="image/webp" srcSet={`${picture}.webp`} />
            <source type="image/jpg" srcSet={`${picture}.jpg`} />
            <img src={`${picture}.jpg`} alt={`pica`} />
          </picture>
        </div>
        <div className="genre-card__desc">
          <h1 className="genre-card__desc--name">{name_ru.replaceAll('_', ' ')}</h1>
        </div>
      </Link>
    </div>
  );
};

export default GenreCard;
