export default number => {
  if (isNaN(Number(number)) || number === Infinity) return 0;
  let str = number.toString();
  if (!str) return 0;
  let i = 0;
  if (number < 10000) {
    while (str.length > 4) {
      i++;
      str = str.substring(0, str.length - 3);
    }
  } else {
    while (str.length > 3) {
      i++;
      str = str.substring(0, str.length - 3);
    }
  }
  for (let j = 0; j < i; j++) {
    str += "K";
  }
  return str;
};
